






















import Vue from 'vue'
import FooterMobile from './footer-mobile.vue'
import FooterNonMobile from './footer-non-mobile.vue'
import SectionContainer from '~/components/layouts/section-container/section-container.vue'
import { CaptionSmall } from '~/components/commons/bases/typography'
import { getCurrentYearTH } from '~/services/utils'

export default Vue.extend({
  components: {
    SectionContainer,
    FooterMobile,
    FooterNonMobile,
    CaptionSmall
  },
  data() {
    return {
      featureTigTok: this.$features('DWB-638-UPDATE-FOOTER-F'),
      getCurrentYearTH: getCurrentYearTH()
    }
  }
})
