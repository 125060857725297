import { merge } from 'lodash'
const promotionRoutes = {
  '/promotions/delivery': [
    {
      path: '/promotions/free-delivery-contract-at-home',
      utmCampaign: 'delivery3hr'
    }
  ],
  '/promotions/movetocar4cash': [
    { path: '/promotions/move-to-car4cash', utmCampaign: 'movetoc4c' }
  ],
  '/promotions/1day': [{ path: '/promotions/1-day', utmCampaign: 'c4c1day' }],

  '/promotions/ew-protect': [
    { path: '/promotions/4w-protect-auto-parts', utmCampaign: 'c4cprotect' }
  ],
  '/promotions/bigbike100': [
    { path: '/promotions/2W-big-bike-loan', utmCampaign: 'bigbike100' }
  ],
  '/promotions/revolving-loan': [
    {
      path: '/promotions/car-loan-revolving-campaign',
      utmCampaign: 'revolvingloan'
    }
  ],
  '/promotions/special-rate': [
    {
      path: '/promotions/c4c-app-in-get-special-rate',
      utmCampaign: 'c4csmartchoice027'
    }
  ],
  '/promotions/member-get-member': [
    { path: '/promotions/member-get-member', utmCampaign: 'c4cmgmmay22' }
  ],
  '/promotions/auto-debit': [
    {
      path: '/promotions/apply-auto-debit-get-the1-points',
      utmCampaign: 'c4cautodebit'
    }
  ],
  '/promotions/keychain': [
    {
      path: '/promotions/apply-car-loan-get-premium-keychain',
      utmCampaign: 'keychain22'
    }
  ],
  '/promotions/welcome-back-23q2': [
    {
      path: '/promotions/23Q2-welcome-back-apply-car-loan-get-extra-the1-point',
      utmCampaign: 'welcomebackapr2023'
    }
  ],
  '/promotions/smile-uv': [
    {
      path: '/promotions/car-loan-lowest-rate-and-easier-approval',
      utmCampaign: 'smilefeb22'
    }
  ],
  '/promotions/promptchai': [
    {
      path: '/promotions/c4c-promptchai-rehit-22',
      utmCampaign: 'c4cpromptchairehit22'
    }
  ],
  '/promotions/20years-23q2': [
    {
      path: '/promotions/c4c-20years-voucher-23q2',
      utmCampaign: 'c4c20th_voucher_23q2'
    }
  ],
  '/promotions/apply-car-loan': [
    {
      path: '/promotions/apply-car-loan-get-premium-tumbler',
      utmCampaign: 'tumbler23'
    }
  ],
  '/promotions/apply-car-loan-23q3': [
    {
      path: '/promotions/apply-car-loan-get-usb-cable',
      utmCampaign: 'c4c20yrsthe1_23q3'
    }
  ],
  '/promotions/bottle': [
    {
      path: '/promotions/apply-car-loan-get-foldable-bottle',
      utmCampaign: 'c4c20th_bottle_23q4'
    }
  ],
  '/promotions/necessary': [
    {
      path: '/promotions/c4c-necessary',
      utmCampaign: 'necessary'
    }
  ],
  '/promotions/giveaway-2024Q3': [
    {
      path: '/promotions/new-customer-giveaway',
      utmCampaign: 'c4c_giveaway_24q3q4'
    }
  ],
  '/promotions/prochamber': [
    {
      path: '/promotions/c4c-thai-chamber',
      utmCampaign: 'prochamber24_c4c'
    }
  ]
}

export default merge(promotionRoutes)
